export const QUESTION_TYPE_MCQ = 'MCQ';
export const QUESTION_TYPE_TEXT = 'text';
export const QUESTION_TYPE_MULTISELECT = 'MultiSelect';

export const INT_TYPE = 'int';
export const STRING_TYPE = 'str';

export const WIDGET_TYPE = {
	POLL: 'POLLS', // type 5
	SURVEY: 10,
	BANNER: 3,
	_4: 4,
	_9: 9,
	_12: 12,
	_3: 3,
};

export const spinningWheelData = [
	{
		image: {
			uri: 'https://d2rstorage2.blob.core.windows.net/widget/January/9/d050f962-88ed-4551-8fa7-8e4ae8d11613/1736421006910.webp',
		},
	},
	{
		image: {
			uri: 'https://d2rstorage2.blob.core.windows.net/widget/January/9/3415e827-f5f7-4f39-a952-695848ce652b/1736421156968.webp',
		},
	},
	{
		image: {
			uri: 'https://d2rstorage2.blob.core.windows.net/widget/January/9/a4d878b9-ca3d-4e62-9982-c638553176bf/1736421192955.webp',
		},
	},

	{
		image: {
			uri: 'https://d2rstorage2.blob.core.windows.net/widget/January/9/9217e151-9ec7-4b15-942e-361f9978244a/1736421267685.webp',
		},
	},
	{
		image: {
			uri: 'https://d2rstorage2.blob.core.windows.net/widget/January/9/9aae245a-b937-4775-8925-900f7153be13/1736421298149.webp',
		},
	},

	{
		image: {
			uri: 'https://d2rstorage2.blob.core.windows.net/widget/January/9/a3e7c2ca-ebd8-4d32-a13d-c815b3ee3c89/1736420902127.webp',
		},
	},
];

export const WEBVIEW_EVENTS = {
	SLOT_MACHINE_ATTEMPTS: 'slot-machine-attempts-exhausted',
	INSUFFICIENT_COIN_BALANCE: 'insufficient-coin-balance',
	NAVIGATION: 'navigation',
	DISPATCH: 'dispatch',
	DOWNLOAD_FILE: 'download-file',
	GET_CAMERA_PERMISSION: 'get-camera-permission',
	GET_LOCATION_PERMISSION: 'get-location-permission',
	OPEN_GALLERY: 'open-gallery',
	OPEN_CAMERA: 'open-camera',
	VIDEO_COMPRESS: 'video-compress',
	IMAGE_COMPRESS: 'image-compress',
	GET_VIDEO_META: 'get-video-meta',
	UPLOAD_BACKGROUND_VIDEO: 'upload-background-video',
	SHARE_IMAGE: 'share-image',
};

export const KYC_PENDING = 'Pending';

export const KYC_REJECT = 'Rejected';

export const KYC_APPROVED = 'Approved';

export const CLAIMED = 'Claimed';

export const UNCLAIMED = 'Unclaimed';

export const PROCESSING = 'Processing';

export const RN_TO_WEBVIEW_EVENTS = {
	OPEN_CAMERA_COMPLETE: 'open-camera-complete',
	OPEN_GALLERY_COMPLETE: 'open-gallery-complete',
	GET_VIDEO_META_COMPLETE: 'get-video-meta-complete',
	VIDEO_COMPRESS_PROGRESS: 'video-compress-progress',
	IMAGE_COMPRESS_COMPLETE: 'image-compress-complete',
	VIDEO_COMPRESS_COMPLETE: 'video-compress-complete',
};

export const CHAT_CONTENT_TYPE = {
	TEXT: 'TEXT',
	IMAGE: 'IMAGE',
};

export const CHAT_SELF_NAME = 'You';

export const BRAND_PROFILE_SCREEN_NAME = 'BrandProfile';

export const studyDemoQuestions = {
	status: 'OK',
	data: {
		user_id: '',
		survey_id: 4,
		data: {
			questions: [
				{
					id: 0,
					options: [
						{
							id: 0,
							label: 'Hindustan Unilever (HUL)',
							value: 'Hindustan Unilever (HUL)',
						},
						{
							id: 1,
							label: 'Dabur',
							value: 'Dabur',
						},
						{
							id: 2,
							label: "Hershey's",
							value: "Hershey's",
						},
						{
							id: 3,
							label: 'Nestle',
							value: 'Nestle',
						},
						{
							id: 4,
							label: 'Cadbury',
							value: 'Cadbury',
						},
						{
							id: 5,
							label: 'Godrej',
							value: 'Godrej',
						},
						{
							id: 6,
							label: 'Coca Cola',
							value: 'Coca Cola',
						},
						{
							id: 7,
							label: 'कोई भी नहीं ',
							value: 'कोई भी नहीं ',
						},
					],
					question: 'इनमें से किन ब्रांड के सेल्समैन आपके पास आते है?',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					next_question: 2,
					question_type: 'multiple_select_question',
				},
				{
					id: 2,
					options: [
						{
							id: 0,
							label: 'फ्रिज (VisiCooler)',
							value: 'फ्रिज (VisiCooler)',
							image_url:
								'https://d2rstorage.retailpulse.ai/widget/May/23/f8767ece-619c-489c-8b9c-817efcd815e9/1716478969593.webp',
						},
						{
							id: 1,
							label: 'टेबल टॉप',
							value: 'टेबल टॉप',
							image_url:
								'https://d2rstorage.retailpulse.ai/widget/May/23/57ea0b88-f1ec-44c9-96c3-f822fc8cf31d/1716478374885.webp',
						},
						{
							id: 2,
							label: 'खड़ा स्टैंड / रैक',
							value: 'खड़ा स्टैंड / रैक',
							image_url:
								'https://d2rstorage.retailpulse.ai/widget/May/23/9e8b5478-5543-4f01-8ffa-78134dd2d255/1716478245388.webp',
						},
						{
							id: 3,
							label: 'कोई भी नहीं',
							value: 'कोई भी नहीं',
						},
					],
					question: 'आपके पास कंपनी द्वारा दी गयीं कौनसी चीज़ें हैं?',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					next_question: 3,
					question_type: 'multiple_select_question_image',
				},
				{
					id: 3,
					options: [
						{
							id: 0,
							label: 'हाँ',
							value: 'हाँ',
							next_question: 4,
						},
						{
							id: 1,
							label: 'नहीं',
							value: 'नहीं',
							next_question: 4,
						},
					],
					question:
						'क्या आपको कभी किसी कंपनी ने उनके प्रोडक्ट डिस्प्ले पर लगाने के लिए पैसे या बिल पर छुट दी?',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					question_type: 'single_select_question',
				},
				{
					id: 4,
					options: [
						{
							id: 0,
							label: 'हाँ',
							value: 'हाँ',
							next_question: 5,
						},
						{
							id: 1,
							label: 'नहीं',
							value: 'नहीं',
							next_question: 5,
						},
						{
							id: 2,
							label: 'मुझे सेल्समैन की मदद की ज़रूरत पड़ेगी',
							value: 'मुझे सेल्समैन की मदद की ज़रूरत पड़ेगी',
							next_question: 5,
						},
					],
					question:
						'अगर कंपनी आपको खुदसे प्रोडक्ट डिस्प्ले पर लगाने के लिए पैसे दे, तो क्या आप कर पाएंगे?',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					question_type: 'single_select_question',
				},
				{
					id: 5,
					options: [
						{
							id: 0,
							label: 'शेल्फ/अलमारी का 1-2 खंड',
							value: 'शेल्फ/अलमारी का 1-2 खंड',
							image_url:
								'https://d2rstorage.retailpulse.ai/widget/May/23/4c950c90-3bb4-40bd-96ee-9390d6075c56/1716478320895.webp',
						},
						{
							id: 1,
							label: 'हैंगर (लड़िया)',
							value: 'हैंगर (लड़िया)',
							image_url:
								'https://d2rstorage.retailpulse.ai/news/April/9/3ad27486-2d17-4463-9805-7245bda76567/banner/images/1712664816698.png',
						},
						{
							id: 2,
							label: 'खड़ा स्टैंड / रैक',
							value: 'खड़ा स्टैंड / रैक',
							image_url:
								'https://d2rstorage.retailpulse.ai/widget/May/23/9e8b5478-5543-4f01-8ffa-78134dd2d255/1716478245388.webp',
						},
						{
							id: 3,
							label: 'टेबल टॉप',
							value: 'टेबल टॉप',
							image_url:
								'https://d2rstorage.retailpulse.ai/widget/May/23/57ea0b88-f1ec-44c9-96c3-f822fc8cf31d/1716478374885.webp',
						},
						{
							id: 4,
							input: 'string',
							label: 'अन्य (कृपया लिखे)',
							value: 'अन्य (कृपया लिखे)',
							on_select: 'open_text_box',
							max_length: 100,
						},
					],
					question: 'आप प्रोडक्ट को किस तरह से डिस्प्ले पर लगा सकते है?',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					next_question: 7,
					question_type: 'multiple_select_question_image',
				},
				{
					id: 7,
					options: [
						{
							id: 0,
							label: 'हाँ',
							value: 'हाँ',
							next_question: 9,
						},
						{
							id: 1,
							label: 'नहीं',
							value: 'नहीं',
							next_question: 9,
						},
					],
					question: 'क्या आपके पास GST नंबर है?',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					question_type: 'single_select_question',
				},
				{
					id: 9,
					options: {
						id: 0,
					},
					question: 'अपनी दुकान की अंदर से 2 फोटो खींचे',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					no_of_images: 2,
					next_question: 10,
					question_type: 'image_upload',
					preview_content:
						'https://d2rstorage.retailpulse.ai/widget/May/20/bc7593bb-f06f-4b55-98cf-3810021360e2/1716187809686.webp',
					question_sub_text: 'दोनों फोटो अलग-अलग दिशाओं से खींचे। कृपया सेल्फ़ी नहीं खींचे 🙏',
				},
				{
					id: 10,
					options: {
						id: 0,
					},
					question: 'अपनी दुकान की बाहर से 2 फोटो खींचे',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					no_of_images: 2,
					next_question: 11,
					question_type: 'image_upload',
					preview_content:
						'https://d2rstorage.retailpulse.ai/widget/May/20/2b354d50-3c4f-4e2d-9f24-cf3fd6536deb/1716187768930.webp',
					question_sub_text: 'एक फोटो में दुकान के साथ-साथ दुकान का बोर्ड भी आना चाहिए।',
				},
				{
					id: 11,
					options: {
						id: 0,
					},
					question: 'अपनी दुकान का एक अंदर-बाहर से वीडियो बनाये',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					next_question: 12,
					question_type: 'video_upload',
					preview_content:
						'https://d2rstorage.retailpulse.ai/ams-videos/amsjobs/a24dbd74-46a9-401f-82df-4415efffe80c/2024-05-20/2ea5c729-6c7e-4e8f-b9d5-26ce25e434ba/1716184874VisiBoost%20Sample%20Video.mp4',
					question_sub_text:
						'आपकी दुकान के अनुसार आपको पैसे मिलेंगे, कृपया उदाहरण में दिया वीडियो देखें 👉',
				},
				{
					id: 12,
					options: {
						id: 0,
					},
					question: 'आप हर हफ्ते दुकान के लिए लगभग कितने की ख़रीदी करते हैं?',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					next_question: 13,
					question_type: 'short_answer',
				},
				{
					id: 13,
					options: {
						id: 0,
					},
					question: 'आप एक महीने में लगभग कितने की बिक्री कर लेते है? (मासिक टर्नओवर)',
					submit_key: 'आगे बढ़ें',
					survey_name: 'VisiBoost_Profiling',
					next_question: -1,
					question_type: 'short_answer',
				},
			],
			start_question: 0,
		},
	},
};
